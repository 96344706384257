.brown { color: #5e1e32; }
.pine-green { color: #01796f; }

.bg-striped-light-green {
  background: repeating-linear-gradient(
    -45deg,
    rgba(158, 235, 207, 0.2),
    rgba(158, 235, 207, 0.2) 10px,
    #ffffff 10px,
    #ffffff 20px
  );
}
